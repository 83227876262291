import React from "react";
import { Link, graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import Img from "gatsby-image";
import Integrationst from "../../images/Integration-Strategy.png";
import Applicationin from "../../images/Application-Integration.png";
import Processauto from "../../images/Process-Automation.png";
import Apimang from "../../images/API-Management-choice.png";
// import comment from "../../images/comment.png";
import Wso2 from "../../images/partners/wso2.png";
import Symbox from "../../images/partners/Symbox.png";
import Salesf from "../../images/partners/salesforce.png";

import ContactUs from "../../components/ContactUs";

const Productinnovation = (props) => {
  return (
    <Layout bodyClass="page-services">
      <Seo
        title="Enterprise Integration"
        description="Enterprise integration is a common and much needed part of IT strategy of large organisations. 
        It is all about establishing and simplifying critical processes, 
        sharing important information with correct audiences and maximising opportunities. 
        Enterprise Integration is a wide subject that require a series of different skills and tooling. 
        iTelaSoft has been engaging with many different customers to connect applications, 
        tools and user communities with standard and custom integration strategies."
      />
      <div className="intro intro-banner">
        <div className="intro-banner-img" id="up">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
            className="hero-banner"
          />
        </div>
        <div className="container">
          <div className="row hero-section">
            <div className="col-12 wrap-page-title">
              <h1 className="page-title">Enterprise Integration</h1>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-6 section-2">
        <div className="row">
          <div className="col-12 mt-4 text-center">
            <div className="sub-title-one">Enterprise Integration</div>
            <hr />
            <div className="discription">
              <p>
                Enterprise integration is a common and much needed part of IT
                strategy of large organisations. It is all about establishing
                and simplifying critical processes, sharing important
                information with correct audiences and maximising opportunities.
                Enterprise Integration is a wide subject that require a series
                of different skills and tooling. iTelaSoft has been engaging
                with many different customers to connect applications, tools and
                user communities with standard and custom integration
                strategies.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img
              alt="product innovation"
              className="img-inno"
              src={Integrationst}
            />
          </div>
          <div className="col-12 col-md-6">
            <div className="space">
              <h4>Integration Strategy</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  iTelaSoft helps many organisations to plan their integration
                  strategies, implement them and govern them. Our team with the
                  experience of different integrations requirements and tooling
                  has been closely partnering with our customers to integrate
                  with their digital ecosystem.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 p-0 order-md-first order-last">
            <div className="space">
              <h4>API Management</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  API management is a key aspect of enterprise integration
                  allowing an organisation to share their data and application
                  assets with its market and connect with partners. Easy
                  adoption, security and developer experience are decisive
                  factors in API management journey. iTelaSoft has a series of
                  domain experts who support our customers in strategizing and
                  implementing managed APIs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="mvp fast track" className="img-inno" src={Apimang} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="Nandika" className="img-inno" src={Processauto} />
          </div>
          <div className="col-12 col-md-6 p-0">
            <div className="space">
              <h4>Process Automation</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Many enterprises are continuously struggling with automating
                  and improving their ever changing business processes.
                  Traditional application development renders too slow and rigid
                  for solving problems in this domain. iTelaSoft has been
                  working extensively in process automation and creating digital
                  twins using BPM and Low/No Code tools, delivering much faster
                  with agility.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 p-lg-0 order-md-first order-last">
            <div className="space">
              <h4>Application Integration</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Many applications are built with limited thought of
                  collaborating with other systems. Some legacy applications
                  never consider this. In the real world, especially in an
                  enterprise, applications should talk to each other to achieve
                  faster services and reduce operational costs. One core
                  competency of our team is providing a wide spectrum of
                  technical expertise in application integration methods.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img
              alt="dedicated teams"
              className="img-inno"
              src={Applicationin}
            />
          </div>
        </div>
        <div className="row partners enp-int">
          <div className="col-12">
            <div className="space">
              <h4>
                We work with a series of reputed technology partners
                <br />
                for enterprise integration standard tooling.
              </h4>

              <div className="img-grid">
                <div className="card">
                  <a
                    href="https://wso2.com/partners/?c=Australia&p=&t="
                    target="_blank"
                  >
                    {" "}
                    <img className="img-partner" alt="Partners" src={Wso2} />
                  </a>
                </div>
                <div className="card">
                  <a href="https://www.symbox.com/">
                    {" "}
                    <img className="img-partner" alt="Partners" src={Symbox} />
                  </a>
                </div>
                <div className="card">
                  <a
                    href="https://appexchange.salesforce.com/appxConsultingListingDetail?listingId=a0N4V00000IdvYGUAZ"
                    target="_blank"
                  >
                    {" "}
                    <img className="img-partner" alt="Partners" src={Salesf} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row wrap-contact-us">
          <ContactUs />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "Enterprise-Integration.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default Productinnovation;
